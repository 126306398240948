<template>
  <layout name-ch="13+1血压及血管风险管理报告（二）" name-en="13+1 Blood Pressure and Vascular Risk Management">
    <chart-line style="margin-top: 30px;" :chart-data="{name: '脉压差', data: pressureData}"></chart-line>
    <chart-line style="margin-top: 30px;" :chart-data="{name: '心率', data: pressureData}"></chart-line>
    <div class="title"> <span>监测报告结果分析</span></div>
    <div class="result-panel">
      <div class="result-title">基础评价</div>
      <div class="result-content mb30">{{result.jcpj}}</div>
      <div class="result-title">报告结果</div>
      <div class="result-content" v-html="replaceBreak(result.bgjg)"></div>
    </div>
  </layout>
</template>

<script type="text/ecmascript-6">
import Layout from './layout';
import ChartLine from './chart-line';

export default {
	components: { Layout, ChartLine },
	props: {
		pressureData: {
			type: Array,
			default: () => [],
		},
		result: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
	},
};
</script>

<style lang="less" scoped>
.title {
  border-bottom: #5694F7 solid 1px;
  padding: 30px 0 15px;
  color: #3569B9;
  font-size: 18px;
  font-weight: 700;
  span {
    display: inline-block;
    border-left: #5694F7 solid 5px;
    padding-left: 10px;
  }
}
.result-panel {
  margin-top: 25px;
  padding: 20px 10px;
  border: #5694F7 solid 3px;
  .result-title {
    width: 120px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    margin-bottom: 20px;
    background-color: #5694F7;
    font-weight: 700;
  }
  .result-content {
    font-size: 18px;
    color: #3569B9;
  }
}
.mb30 {
  margin-bottom: 30px;
}
</style>
