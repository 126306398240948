<template>
	<div class="line" ref="line"></div>
</template>

<script>
import echarts from 'echarts';

export default {
	props: {
		chartData: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		initChart() {
			let title = '';
			let max = 0;
			let min = 0;
			let limit1 = 0;
			let limit2 = 0;
			let standart = 0;
			let color = '';
			const xAxis = this.chartData.data.map((item) => item.create_time);
			let data = [];
			switch (this.chartData.name) {
				case '收缩压':
					title = '90-139普通范围';
					max = 160;
					min = 90;
					limit1 = 90;
					limit2 = 139;
					standart = 120;
					color = '#2F87DF';
					data = this.chartData.data.map((item) => item.systolic);
					break;
				case '舒张压':
					title = '60-89普通范围';
					max = 140;
					min = 60;
					limit1 = 60;
					limit2 = 89;
					standart = 80;
					color = '#169128';
					data = this.chartData.data.map((item) => item.diastolic);
					break;
				case '脉压差':
					title = '35-45普通范围';
					max = 70;
					min = 30;
					limit1 = 35;
					limit2 = 45;
					standart = 40;
					color = '#fdad00';
					data = this.chartData.data.map((item) => item.diff);
					break;
				default:
					title = '65-75普通范围';
					max = 100;
					min = 60;
					limit1 = 65;
					limit2 = 75;
					standart = 70;
					color = '#F4641B';
					data = this.chartData.data.map((item) => item.heart);
					break;
			}
			const chart = echarts.init(this.$refs.line);
			var options = {
				backgroundColor: '#f0f8ff',
				title: {
					text: title,
					textStyle: {
						color,
						fontWeight: 500,
						fontSize: 16,
					},
					top: 70,
					right: 50,
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						return params[0].seriesName + ':' + params[0].data;
					},
				},
				legend: {
					data: [this.chartData.name],
					top: 45,
					right: 50,
					textStyle: {
						color,
						fontWeight: 500,
						fontSize: 16,
					},
				},
				color: [color],
				grid: {
					left: '3%',
					right: '4%',
					bottom: '10%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					axisLine: {
						lineStyle: {
							color: '#297ddf',
						},
					},
					axisTick: {
						show: false,
					},
					boundaryGap: false,
					data: xAxis,
				},
				yAxis: {
					type: 'value',
					name: '(mmHg)',
					axisLine: {
						lineStyle: {
							color: '#297ddf',
						},
					},
					splitLine: {
						show: false,
					},
					max: max,
					min: min,
				},
				series: [
					{
						type: 'line',
						name: '区间',
						markArea: {
							data: [
								[
									{
										yAxis: limit2,
										itemStyle: {
											color: '#3FC0EA',
											opacity: 0.07,
										},
									},
									{
										yAxis: limit1,
									},
								],
							],
						},
					},
					{
						name: this.chartData.name,
						type: 'line',
						data,
						lineStyle: {
							width: '3',
							color: color,
						},
					},
					{
						name: '标准',
						type: 'line',
						lineStyle: {
							type: 'dotted',
							width: '3',
							color: color,
						},
						symbol: false,
						data: new Array(14).fill(standart),
					},
				],
			};
			chart.setOption(options);
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		chartData() {
			this.initChart();
		},
	},
};
</script>

<style scoped>
.line {
  width: 100%;
  height: 350px;
}
</style>
