<template>
  <div class="quantify-container">
    <header class="header">
      <div class="header-title">
        <div class="header-title--left">
          <div class="header-img">
            <img src="@/assets/img/blood-pressure.png" />
          </div>
          <div>
            <p class="name-ch">{{ nameCh }}</p>
            <p class="name-en">{{ nameEn }}</p>
          </div>
        </div>
      </div>
      <div class="divider divider__bold" style="margin-bottom: 5px"></div>
      <div class="divider divider__normal"></div>
      <div class="header-info">
        <table class="table">
          <tr>
            <th>区域门店</th>
            <td>{{ userInfo.md }}</td>
            <th>姓名</th>
            <td>{{ userInfo.name }}</td>
            <th>性别</th>
            <td>{{ userInfo.sex_show }}</td>
            <th>年龄</th>
            <td>{{ userInfo.age }}</td>
          </tr>
          <tr>
            <th>身高</th>
            <td colspan="2">{{ userInfo.sg }}cm</td>
            <th>体重</th>
            <td colspan="2">{{ userInfo.tz }}Kg</td>
            <th>BMI</th>
            <td>{{ userInfo.bmi }}</td>
          </tr>
          <tr>
            <th>疾病</th>
            <td colspan="7">{{ userInfo.jbxx }}</td>
          </tr>
        </table>
      </div>
      <div class="divider divider__dashed mb20"></div>
    </header>
    <section class="section">
      <slot class="content-panel"></slot>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		nameCh: String,
		nameEn: String,
	},
	computed: {
		...mapGetters({ userInfo: 'reportUserData' }),
	},
};
</script>

<style lang="less" scoped>
.quantify-container {
  background-color: #fff;
  width: 1100px;
  min-height: 1665px;
  padding: 15px 20px 0;
  display: flex;
  flex-direction: column;
}
.header {
  color: #005ab3;
  .header-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .header-img {
      margin-right: 12px;
      height: 85px;
      img {
        height: 100%;
      }
    }
    &--left {
      display: flex;
      .name-ch {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 5px;
      }
      .name-en {
        font-size: 22px;
      }
    }
    &--right {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .title-branch {
        font-size: 22px;
        font-weight: bold;
      }
      .title-date {
        font-size: 16px;
        font-weight: bold;
        span {
          margin-left: 20px;
        }
      }
    }
  }
  .header-info {
    margin: 15px 0;;
    .table {
      font-size: 16px;
      table-layout: fixed;
      th, td {
        border: #266dd9  solid 1px;
        padding: 10px 0;
      }
      th {
        color: #266dd9;
        font-weight: 500;
      }
      td {
        color: #123aa2;
        font-weight: 700;
      }
      width: 100%;
      text-align: center;
    }
  }
}
.section {
  flex: 1;
}
.divider {
  background-color: #005ab3;
  width: 100%;
  &__bold {
    height: 3px;
  }
  &__normal {
    height: 1px;
  }
  &__dashed {
    background-color: transparent;
    border-bottom: dashed 1px #005ab3;
  }
}
</style>
