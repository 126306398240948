<template>
  <layout name-ch="13+1血压及血管风险管理报告（一）" name-en="13+1 Blood Pressure and Vascular Risk Management">
    <div class="flex">
      <table class="flex-auto">
        <tr>
          <th>监测时间点</th>
          <th>收缩压</th>
          <th>舒张压</th>
          <th>脉压差</th>
          <th>心率</th>
          <th>测量前行为</th>
        </tr>
        <tr v-for="(item, index) in pressureData" :key="index">
          <td>{{item.create_time}}</td>
          <td>{{item.systolic}}</td>
          <td>{{item.diastolic}}</td>
          <td>{{item.diff}}</td>
          <td>{{item.heart}}</td>
          <td>{{item.behavior.join('、')}}</td>
        </tr>
      </table>
      <div class="ml-15">
        <table>
          <tr><td colspan="2">基础数据总汇</td></tr>
          <tr>
            <th>血压超标</th>
            <td>{{pressureSummary.overTimes}}次</td>
          </tr>
          <tr>
            <th>平均血压</th>
            <td>{{pressureSummary.avgPressure}}mmHg</td>
          </tr>
          <tr>
            <th>平均脉压差</th>
            <td>{{pressureSummary.avgDiff}}mmHg</td>
          </tr>
          <tr>
            <th>收缩压波动</th>
            <td>{{pressureSummary.waveSystolic}}mmHg</td>
          </tr>
          <tr>
            <th>舒张压波动</th>
            <td>{{pressureSummary.waveDiastolic}}mmHg</td>
          </tr>
          <tr>
            <th>平均心率</th>
            <td>{{pressureSummary.avgHeart}}次/min</td>
          </tr>
          <tr>
            <th>心率波动</th>
            <td>{{pressureSummary.waveHeart}}次/min</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="title">13+1 血压曲线</div>
    <chart-line style="margin-top: 30px;" :chart-data="{name: '收缩压', data: pressureData}"></chart-line>
    <chart-line style="margin-top: 30px;" :chart-data="{name: '舒张压', data: pressureData}"></chart-line>
  </layout>
</template>

<script type="text/ecmascript-6">
import Layout from './layout';
import ChartLine from './chart-line';

export default {
	components: { Layout, ChartLine },
	props: {
		pressureData: {
			type: Array,
			default: () => [],
		},
		pressureSummary: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  margin-top: 15px;
}

.flex-auto {
  flex: auto;
}

.ml-15 {
  margin-left: 15px;
  width: 400px;
  table {
    height: 100%;
  }
  th, td {
    padding: 10px 0;
  }
}

table {
  font-size: 16px;
  table-layout: fixed;
  th, td {
    border: #266dd9 solid 1px;
  }
  th {
    color: #266dd9;
  }
  td {
    color: #3569b9;
    font-size: 18px;
  }
  tr:first-child {
    background-color: #3569b9;
    th, td {
      color: #fff;
    }
  }
  width: 100%;
  text-align: center;
}

.title {
  color: #3569b9;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 30px 0 10px;
  border-bottom: #2a6fdd solid 1px;
}
</style>
